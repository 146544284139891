//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'AppFab',
  data: function data() {
    return {
      fab: false
    };
  },
  methods: {
    onScroll: function onScroll() {
      if (typeof window === 'undefined') return;
      var top = window.pageYOffset || document.documentElement.offsetTop || 0;
      this.fab = top > 300;
    },
    toTop: function toTop() {
      this.$vuetify.goTo(0);
    }
  }
};