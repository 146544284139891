var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { dark: "" } },
    [
      _c("router-view"),
      _c(
        "v-snackbar",
        {
          attrs: {
            timeout: 3000,
            app: "",
            top: "",
            centered: "",
            color: _vm.snackbar.color
          },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function(ref) {
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { icon: "" },
                        on: {
                          click: function($event) {
                            _vm.snackbar.show = false
                          }
                        }
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.snackbar.show,
            callback: function($$v) {
              _vm.$set(_vm.snackbar, "show", $$v)
            },
            expression: "snackbar.show"
          }
        },
        [_vm._v(" " + _vm._s(_vm.snackbar.text) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }