import Vue from 'vue';
import Vuex from 'vuex';
import app from './modules/app';
import auth from './modules/auth';
Vue.use(Vuex);
var store = new Vuex.Store({
  modules: {
    app: app,
    auth: auth
  }
});
export default store;