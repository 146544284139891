import "/root/workspace/node_modules/core-js/modules/es.array.iterator.js";
import "/root/workspace/node_modules/core-js/modules/es.promise.js";
import "/root/workspace/node_modules/core-js/modules/es.object.assign.js";
import "/root/workspace/node_modules/core-js/modules/es.promise.finally.js";
import "core-js/modules/es.array.concat.js";
import Vue from 'vue';
import axios from 'axios';
import { Model } from 'vue-api-query';
import echarts from 'echarts';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './theme/default.sass';
import App from './App.vue';
import '@mdi/font/css/materialdesignicons.css';
Model.$http = axios;
Vue.config.productionTip = false;
Vue.prototype.$echarts = echarts;
var app = new Vue({
  router: router,
  store: store,
  vuetify: vuetify,
  render: function render(h) {
    return h(App);
  }
});

Vue.prototype.__ = function (key) {
  var _app$$vuetify$lang;

  for (var _len = arguments.length, parrams = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    parrams[_key - 1] = arguments[_key];
  }

  return (_app$$vuetify$lang = app.$vuetify.lang).translator.apply(_app$$vuetify$lang, [key].concat(parrams));
};

app.$mount('#app');