import _toConsumableArray from "/root/workspace/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _typeof from "/root/workspace/node_modules/@babel/runtime/helpers/esm/typeof";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";

/* eslint-disable no-console */
import axios from 'axios';
import { getToken } from '@/utils/auth';
import store from '../store';
import router from '../router';

var removeEmptyProps = function removeEmptyProps(obj) {
  if (obj) {
    var newObj = Array.isArray(obj) ? [] : {};
    Object.keys(obj).forEach(function (key) {
      if (obj[key] && _typeof(obj[key]) === 'object') {
        newObj[key] = removeEmptyProps(obj[key]); // recurse
      } else if (obj[key] != null) {
        // include undefined
        newObj[key] = obj[key]; // copy value
      }
    });
    return newObj;
  }

  return obj;
}; // create an axios instance


var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 10000,
  // request timeout
  withCredentials: true,
  transformRequest: [function (data) {
    return removeEmptyProps(data);
  }].concat(_toConsumableArray(axios.defaults.transformRequest))
}); // request interceptor

service.interceptors.request.use(function (config) {
  var configClone = config;
  configClone.headers.Authorization = "ds ".concat(getToken());
  return configClone;
}, function (error) {
  Promise.reject(error);
}); // response interceptor

service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
 */

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data; // if the custom code is not 20000, it is judged as an error.

  if (res.code !== 0) {
    console.log(res.error_message);
  }

  return res;
}, function (error) {
  if (error.response.status === 401) {
    store.dispatch('logout');
    router.push('/auth/login');
    return Promise.reject(error);
  } // console.log(`err${res.code}`); // for debug


  console.log(error.message);
  return Promise.reject(error);
});
export default service;