import _typeof from "/root/workspace/node_modules/@babel/runtime/helpers/esm/typeof";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      rightDrawer: false,
      snackbar: {
        show: false,
        text: '',
        color: ''
      }
    };
  },
  mounted: function mounted() {
    if ((typeof window === "undefined" ? "undefined" : _typeof(window)) !== undefined && window._VMA === undefined) {
      window._VMA = this;
    }
  },
  created: function created() {
    var _this = this;

    this.$on('SHOW_SNACKBAR', function (e) {
      _this.snackbar = {
        show: true,
        text: e.text,
        color: e.color
      };
    });
    this.$on('AUTH_FAIELD', function () {
      _this.snackbar = {
        show: true,
        text: 'Auth Failed',
        color: 'error'
      };

      _this.$router.push({
        path: '/auth/login',
        query: {
          redirect: _this.$route.path
        }
      });
    });
    this.$on('SERVER_ERROR', function () {
      _this.snackbar = {
        show: true,
        text: 'Server Error',
        color: 'error'
      };
    });
  },
  methods: {}
};