import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.function.name.js";
import Vue from 'vue';
import Router from 'vue-router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css'; // eslint-disable-next-line import/no-cycle

import store from '@/store';
import { getToken } from '@/utils/auth'; // get token from cookie

import { getQueryObject } from '@/utils/index';
import { publicRoute, protectedRoute } from './config';
var routes = publicRoute.concat(protectedRoute);
Vue.use(Router);
var router = new Router({
  mode: 'hash',
  linkActiveClass: 'active',
  routes: routes
}); // router gards

router.beforeEach(function (to, from, next) {
  var _getQueryObject = getQueryObject(window.location.search),
      code = _getQueryObject.code,
      state = _getQueryObject.state;

  if (code != null && state != null) {
    next(false); // next({ name: 'login', query: { code, state }, replace: true });

    window.location.replace("".concat(window.location.protocol, "//").concat(window.location.host).concat(window.location.pathname, "#/auth/login?code=").concat(code, "&state=").concat(state));
  } else {
    NProgress.start();
    var token = getToken();

    if (to.name !== 'login') {
      if (token) {
        store.dispatch('getInfo').then(function () {
          next();
        })["catch"](function () {
          next({
            name: 'login'
          });
        });
      } else {
        next({
          name: 'login',
          query: {
            redirect: to.path
          }
        });
      }
    } else {
      next();
    }
  } // auth route is authenticated

});
router.afterEach(function () {
  NProgress.done();
});
export default router;