var state = {
  mode: 'light',
  themeColor: '#3f51b5',
  theme: 'inidigo'
}; // getters

var getters = {
  getTheme: function getTheme(State) {
    return State.theme;
  },
  getThemeColor: function getThemeColor(State) {
    return State.themeColor;
  }
}; // actions

var actions = {}; // mutations

var mutations = {
  setThemeColor: function setThemeColor(State, payload) {
    State.themeColor = payload;
  },
  setTheme: function setTheme(State, payload) {
    State.theme = payload;
  }
};
export default {
  namespace: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
};