/* eslint-disable import/no-cycle */
import request from '@/utils/http';
export function getSignInUrl() {
  return request({
    url: '/auth/get_sign_in_url',
    method: 'get'
  });
}
export function authLogin() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: '/auth/login',
    method: 'post',
    data: data
  });
}
export function authInfo() {
  return request({
    url: '/auth/info',
    method: 'get'
  });
}