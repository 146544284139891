import _objectSpread from "/root/workspace/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import en from 'vuetify/es5/locale/en';
export default _objectSpread({
  label: 'English',
  menu: {
    home: 'Home',
    catalog: 'Catalog',
    push_notice: 'Push Notice',
    push_endpoint: 'Push Endpoint',
    push_login: 'Push LoginPrompt',
    version: 'Version',
    logout: 'Logout'
  },
  catalog: {
    code: 'Code',
    cover_url: 'Cover',
    original_filename: 'Filename',
    name: 'Name',
    page_count: 'Pages',
    media_code: 'Media Code',
    issue_number: 'Issue Number',
    exclude_page_nums: 'Exclude Pages',
    expire_date: 'Expire Date',
    status_name: 'Status',
    create_at: 'Create At',
    dates: 'Filter Dates',
    uploaded: 'Uploaded',
    processing: 'Processing',
    hosting: 'Hosting',
    hosting_end: 'Hosting End',
    search: 'Search',
    status: 'Status',
    create: 'Create',
    create_catalog: 'Create Catalog',
    file: 'File',
    actions: 'Actions',
    "delete": 'Delete',
    delete_title: 'Delete Alert',
    delete_msg: 'Do you want to delete this catalog?',
    proceed_delete: 'Delete',
    cancel: 'Cancel',
    delete_success: 'Catalog deleted',
    create_success: 'Catalog created',
    close: 'Close',
    save: 'Save',
    items_per_page: 'Items per page',
    size: 'Size',
    exclude_page_nums_hint: '',
    create_error: 'Service error, please try again later.'
  },
  "export": {
    "export": 'Export',
    loading: 'Loading',
    loading_msg: 'Export csv after loading all data',
    loading_done: 'Loading complete',
    exporting_msg: 'Exporting',
    export_failed: 'Export failed'
  },
  notice: {
    date: 'Date',
    body: 'Body',
    category: 'Category',
    end: 'End',
    errors: 'Errors',
    exclude_list: 'Exclude List',
    image: 'Image',
    sd: 'SD',
    specified_list: 'Specified List',
    start: 'Start',
    target: 'Target',
    target_endpoints: 'Target Endpoints'
  },
  endpoint: {
    date: 'Date',
    stat_date: 'Stat Date',
    total: 'Total',
    today: 'This Month'
  },
  push_login: {
    date: 'Date',
    stat_date: 'Stat Date',
    a3d_number: 'No. in 3 Days',
    a7d_number: 'No. in 7 Days',
    today: 'This Month'
  },
  version: {
    create: 'Create',
    create_version: 'Create Version',
    force_update: 'Force Update',
    version_number: 'Version',
    major: 'Major',
    minor: 'Minor',
    revision: 'Revision',
    create_at: 'Create At',
    platform: 'OS',
    build_number: 'Build Number',
    edit: 'Edit'
  }
}, en);