import _objectSpread from "/root/workspace/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Util from '@/utils';
import { mapGetters } from 'vuex';
export default {
  name: 'AppToolbar',
  data: function data() {
    return {};
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['getAvatar', 'getUsername'])), {}, {
    toolbarColor: function toolbarColor() {
      return this.$vuetify.options.extra.mainNav;
    },
    availableLanguages: function availableLanguages() {
      var locales = this.$vuetify.lang.locales;
      return Object.keys(locales).map(function (lang) {
        return {
          text: locales[lang].label,
          value: lang
        };
      });
    },
    localeText: function localeText() {
      var _this = this;

      var find = this.availableLanguages.find(function (item) {
        return item.value === _this.$vuetify.lang.current;
      });
      return find.text;
    },
    breadcrumbs: function breadcrumbs() {
      var _this2 = this;

      var matched = this.$route.matched;
      return matched.map(function (route, index) {
        var to = index === matched.length - 1 ? _this2.$route.path : route.path || route.redirect;

        var text = _this2.$vuetify.lang.t("$vuetify.menu.".concat(route.meta.title));

        return {
          text: text,
          to: to,
          exact: true,
          disabled: false
        };
      });
    }
  }),
  created: function created() {},
  methods: {
    handleDrawerToggle: function handleDrawerToggle() {
      this.$emit('side-icon-click');
    },
    handleFullScreen: function handleFullScreen() {
      Util.toggleFullScreen();
    },
    handleLogut: function handleLogut() {
      if (window.confirm("".concat(this.__('menu.logout'), "?"))) {
        this.$store.dispatch('logout');

        window._VMA.$emit('SHOW_SNACKBAR', {
          text: 'Logout successfull',
          color: 'success'
        });

        this.$router.push('/auth/login');
      }
    },
    handleChangeLocale: function handleChangeLocale(_ref) {
      var value = _ref.value;
      this.$vuetify.lang.current = value;
    },
    handleSetting: function handleSetting() {},
    handleProfile: function handleProfile() {},
    handleGoBack: function handleGoBack() {
      this.$router.go(-1);
    }
  }
};