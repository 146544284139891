//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import AppToolbar from '@/components/AppToolbar';
import AppFab from '@/components/AppFab';
import AppDrawer from '@/components/AppDrawer';
export default {
  name: 'LayoutDefault',
  components: {
    AppToolbar: AppToolbar,
    AppFab: AppFab,
    AppDrawer: AppDrawer
  },
  data: function data() {
    return {
      showDrawer: true
    };
  },
  methods: {
    handleDrawerVisiable: function handleDrawerVisiable() {
      this.$refs.drawer.toggleDrawer();
    }
  }
};