var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "app" },
    [
      _c("app-drawer", { ref: "drawer", staticClass: "app--drawer" }),
      _c("app-toolbar", {
        staticClass: "app--toolbar",
        on: { "side-icon-click": _vm.handleDrawerVisiable }
      }),
      _c(
        "v-main",
        [
          _c("div", { staticClass: "page-wrapper" }, [_c("router-view")], 1),
          _c(
            "v-footer",
            { staticClass: "pa-3 app--footer", attrs: { height: "auto" } },
            [
              _c("span", [
                _vm._v("Dinos © " + _vm._s(new Date().getFullYear()))
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }