import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { protectedRoute as routes } from '@/router/config';
export default {
  name: 'AppDrawer',
  components: {},
  props: {
    expanded: {
      type: Boolean,
      "default": true
    }
  },
  data: function data() {
    return {
      mini: false,
      drawerWidth: 256,
      drawer: true,
      scrollSettings: {
        maxScrollbarLength: 160
      }
    };
  },
  computed: {
    computeLogo: function computeLogo() {
      return '/static/m.png';
    },
    computeMenu: function computeMenu() {
      return routes[0].children;
    }
  },
  created: function created() {},
  methods: {
    handleDrawerCollapse: function handleDrawerCollapse() {
      this.drawerWidth = this.drawerWidth === 256 ? 64 : 256;
    },
    toggleDrawer: function toggleDrawer() {
      this.drawer = !this.drawer;
    },
    computeGroupExpanded: function computeGroupExpanded(item, $route) {
      return $route.matched.map(function (item) {
        return item.path;
      }).includes(item.path);
    }
  }
};