import _objectSpread from "/root/workspace/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import ja from 'vuetify/es5/locale/ja';
export default _objectSpread({
  label: '日本語',
  menu: {
    home: 'ホーム',
    catalog: 'カタログ',
    push_notice: 'プッシュ通知',
    push_endpoint: 'プッシュエンドポイント',
    push_login: '未ログイン者プッシュ通知',
    version: 'バージョン',
    logout: 'ログアウト'
  },
  catalog: {
    code: 'コード',
    cover_url: '表紙',
    original_filename: 'ファイル名',
    name: '名称',
    page_count: 'ページ数',
    media_code: '媒体コード',
    issue_number: '号年月コード',
    exclude_page_nums: '除外ページ',
    expire_date: '有効期限',
    status_name: '状態',
    create_at: '登録時刻',
    dates: '登録時刻',
    uploaded: '処理待ち',
    processing: '処理中',
    hosting: '配信中',
    hosting_end: '配信終了',
    search: '検索',
    status: '状態',
    create: '登録',
    create_catalog: 'カタログ登録',
    file: 'ファイル',
    actions: 'アクション',
    "delete": '削除',
    delete_title: '削除確認',
    delete_msg: '削除しますか？',
    proceed_delete: '削除',
    cancel: 'キャンセル',
    delete_success: 'カタログを削除しました。',
    create_success: 'カタログをアップロードしました。',
    close: 'キャンセル',
    save: '登録',
    items_per_page: 'ページサイズ',
    size: 'サイズ',
    exclude_page_nums_hint: '複数ページを除外処理する場合、半角カンマ「,」で分けてください。\n例：1,2,5',
    create_error: 'サーバーでエラーが発生しました。しばらくしてからもう一度お試しください。'
  },
  "export": {
    "export": '出力',
    loading: 'Loading',
    loading_msg: 'Export csv after loading all data',
    loading_done: 'Loading complete',
    exporting_msg: 'Exporting',
    export_failed: '出力に失敗しました。再度お試してください。'
  },
  notice: {
    date: '日付',
    body: 'メッセージ内容',
    category: '分類',
    end: '配信終了日時',
    errors: '配信エラー数',
    exclude_list: '除外リスト',
    image: '画像URL',
    sd: '検証コード',
    specified_list: '指定リスト',
    start: '配信開始日時',
    target: '配信対象',
    target_endpoints: '配信数'
  },
  endpoint: {
    date: '日付',
    stat_date: '日付',
    total: 'エンドポイント数',
    today: '今月'
  },
  push_login: {
    date: '日付',
    stat_date: '日付',
    a3d_number: '3日後件数',
    a7d_number: '7日後件数',
    today: '今月'
  },
  version: {
    create: '作成',
    create_version: 'バージョン作成',
    force_update: '強制アップデート',
    version_number: 'バージョン',
    major: 'メジャー',
    minor: 'マイナー',
    revision: 'リビジョン',
    create_at: '作成時刻',
    platform: 'OS',
    build_number: 'ビルド番号',
    edit: '編集'
  }
}, ja);