import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { LayoutAuth, LayoutDefault } from '@/components/layouts';
export var publicRoute = [{
  path: '*',
  component: function component() {
    return import('@/views/error/NotFound.vue');
  }
}, {
  path: '/auth',
  component: LayoutAuth,
  meta: {
    title: 'Login'
  },
  redirect: '/auth/login',
  hidden: true,
  children: [{
    path: 'login',
    name: 'login',
    meta: {
      title: 'Login'
    },
    component: function component() {
      return import('@/views/auth/Login.vue');
    }
  }]
}, {
  path: '/404',
  name: '404',
  meta: {
    title: 'Not Found'
  },
  component: function component() {
    return import('@/views/error/NotFound.vue');
  }
}, {
  path: '/500',
  name: '500',
  meta: {
    title: 'Server Error'
  },
  component: function component() {
    return import('@/views/error/Error.vue');
  }
}];
export var protectedRoute = [{
  path: '/',
  component: LayoutDefault,
  meta: {
    title: 'home',
    group: 'apps',
    icon: ''
  },
  redirect: '/notice',
  children: [{
    path: '/notice',
    meta: {
      title: 'push_notice',
      group: 'apps',
      icon: 'mdi-bell-outline'
    },
    component: function component() {
      return import('@/views/notice/index.vue');
    }
  }, {
    path: '/endpoint',
    meta: {
      title: 'push_endpoint',
      group: 'apps',
      icon: 'mdi-link'
    },
    component: function component() {
      return import('@/views/endpoint/index.vue');
    }
  }, {
    path: '/push_login',
    meta: {
      title: 'push_login',
      group: 'apps',
      icon: 'mdi-message-text'
    },
    component: function component() {
      return import('@/views/push_login/index.vue');
    }
  }, {
    path: '/catalog',
    meta: {
      title: 'catalog',
      group: 'apps',
      icon: 'mdi-format-list-bulleted-type'
    },
    component: function component() {
      return import('@/views/catalog/index.vue');
    }
  }, {
    path: '/version',
    meta: {
      title: 'version',
      group: 'apps',
      icon: 'mdi-git'
    },
    component: function component() {
      return import('@/views/version/index.vue');
    }
  }]
}];