var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    { attrs: { color: "primary", dark: "", app: "" } },
    [
      _c("v-app-bar-nav-icon", { on: { click: _vm.handleDrawerToggle } }),
      _c("v-spacer"),
      _c(
        "v-toolbar-items",
        [
          _c(
            "v-menu",
            {
              staticClass: "elelvation-1",
              attrs: {
                "offset-y": "",
                origin: "center center",
                transition: "scale-transition"
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            attrs: { slot: "activator", text: "" },
                            slot: "activator"
                          },
                          on
                        ),
                        [
                          _c("v-icon", { attrs: { medium: "" } }, [
                            _vm._v(" mdi-translate ")
                          ]),
                          _c("span", { staticClass: "ml-2" }, [
                            _vm._v(" " + _vm._s(_vm.localeText) + " ")
                          ])
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item-group",
                    {
                      model: {
                        value: _vm.$vuetify.lang.current,
                        callback: function($$v) {
                          _vm.$set(_vm.$vuetify.lang, "current", $$v)
                        },
                        expression: "$vuetify.lang.current"
                      }
                    },
                    _vm._l(_vm.availableLanguages, function(item) {
                      return _c(
                        "v-list-item",
                        {
                          key: item.value,
                          attrs: { value: item.value },
                          on: {
                            click: function($event) {
                              return _vm.handleChangeLocale(item)
                            }
                          }
                        },
                        [
                          _c("v-list-item-title", {
                            domProps: { textContent: _vm._s(item.text) }
                          })
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: { text: "" },
              on: {
                click: function($event) {
                  return _vm.handleLogut()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.__("menu.logout")) + " ")]
          )
        ],
        1
      ),
      _c(
        "v-toolbar",
        {
          attrs: {
            slot: "extension",
            tag: "div",
            dense: "",
            color: "elevation-0",
            light: ""
          },
          slot: "extension"
        },
        [
          _c("v-icon", [_vm._v("mdi-home")]),
          _c("v-breadcrumbs", {
            staticClass: "pa-3",
            attrs: { items: _vm.breadcrumbs }
          }),
          _c("v-spacer"),
          _c(
            "v-btn",
            { attrs: { icon: "", small: "", color: "black" } },
            [
              _c("v-icon", {
                domProps: { textContent: _vm._s("mdi-arrow-left") },
                on: { click: _vm.handleGoBack }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }