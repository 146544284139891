var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "app-drawer",
      attrs: { app: "", "mini-variant": _vm.mini, width: _vm.drawerWidth },
      on: {
        "update:miniVariant": function($event) {
          _vm.mini = $event
        },
        "update:mini-variant": function($event) {
          _vm.mini = $event
        }
      },
      scopedSlots: _vm._u([
        {
          key: "append",
          fn: function() {
            return [
              _c(
                "div",
                { staticClass: "grey lighten-3" },
                [
                  _vm.drawerWidth === 64
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex",
                            staticStyle: {
                              height: "48px",
                              "align-items": "center",
                              "background-color": "#f5f5f5"
                            }
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "mx-auto",
                                attrs: { width: "64", icon: "", tile: "" },
                                on: { click: _vm.handleDrawerCollapse }
                              },
                              [
                                _c("v-icon", [
                                  _vm._v("mdi-arrow-collapse-right")
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    : [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex",
                            staticStyle: {
                              height: "48px",
                              "align-items": "center",
                              "background-color": "#f5f5f5"
                            }
                          },
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                staticClass: "mr-2",
                                attrs: { icon: "", tile: "" },
                                on: { click: _vm.handleDrawerCollapse }
                              },
                              [
                                _c("v-icon", [
                                  _vm._v("mdi-arrow-collapse-left")
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                ],
                2
              )
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.drawer,
        callback: function($$v) {
          _vm.drawer = $$v
        },
        expression: "drawer"
      }
    },
    [
      _c(
        "v-toolbar",
        { attrs: { color: "primary darken-1", dark: "" } },
        [
          _c("img", {
            attrs: {
              src: _vm.computeLogo,
              height: "36",
              alt: "Dinos Dashboard"
            }
          }),
          _c("v-toolbar-title", { staticClass: "ml-0 pl-3" }, [
            _c("span", { staticClass: "hidden-sm-and-down" }, [
              _vm._v("Dinos Dashboard")
            ])
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "app-drawer__inner" },
        [
          _c(
            "v-list",
            { staticClass: "pa-0", attrs: { dense: _vm.drawerWidth !== 64 } },
            [
              _vm._l(_vm.computeMenu, function(item, key) {
                return [
                  item.children && item.children.length > 0
                    ? [
                        _c(
                          "v-list-group",
                          {
                            key: key,
                            attrs: {
                              "no-action": "",
                              to: item.path,
                              value: _vm.computeGroupExpanded(item, _vm.$route)
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "prependIcon",
                                  fn: function() {
                                    return [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            domProps: {
                                                              textContent: _vm._s(
                                                                item.meta.icon
                                                              )
                                                            }
                                                          },
                                                          "v-icon",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      )
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.__(
                                                    "menu." + item.meta.title
                                                  )
                                                ) +
                                                " "
                                            )
                                          ])
                                        ]
                                      )
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "activator",
                                  fn: function() {
                                    return [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.__(
                                                  "menu." + item.meta.title
                                                )
                                              )
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              true
                            )
                          },
                          _vm._l(item.children, function(subItem) {
                            return _c(
                              "v-list-item",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !subItem.meta.hiddenInMenu,
                                    expression: "!subItem.meta.hiddenInMenu"
                                  }
                                ],
                                key: subItem.name,
                                class: _vm.drawerWidth === 64 ? "pl-4" : "",
                                attrs: { to: subItem.path }
                              },
                              [
                                _vm.drawerWidth === 64
                                  ? [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                domProps: {
                                                                  textContent: _vm._s(
                                                                    subItem.meta
                                                                      .icon
                                                                  )
                                                                }
                                                              },
                                                              "v-icon",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          )
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.__(
                                                      "menu." +
                                                        subItem.meta.title
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  : [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.__(
                                                  "menu." + subItem.meta.title
                                                )
                                              )
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                              ],
                              2
                            )
                          }),
                          1
                        )
                      ]
                    : [
                        _c(
                          "v-list-item",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !item.meta.hiddenInMenu,
                                expression: "!item.meta.hiddenInMenu"
                              }
                            ],
                            key: key,
                            attrs: { to: item.path }
                          },
                          [
                            _c(
                              "v-list-item-icon",
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          item.meta.icon
                                                        )
                                                      }
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.__("menu." + item.meta.title)
                                        )
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            ),
                            _vm.drawerWidth !== 64
                              ? _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", {
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.__("menu." + item.meta.title)
                                        )
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.meta.new
                              ? _c(
                                  "v-list-item-action",
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "green" } },
                                      [_vm._v(" mdi-new-box ")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }