import "core-js/modules/es.object.to-string.js";
import { getToken, setToken, removeToken } from '@/utils/auth';
import { authLogin, authInfo } from '@/api/auth';
var state = {
  token: getToken(),
  user: null
};
var actions = {
  getInfo: function getInfo(_ref) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      authInfo().then(function (response) {
        var data = response.data;
        commit('SET_USER', data);
        resolve(data);
      })["catch"](function (error) {
        reject(error);
      });
    });
  },
  login: function login(_ref2, userInfo) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      authLogin(userInfo).then(function (response) {
        var data = response.data;
        commit('SET_TOKEN', data.access_token);
        setToken(data.access_token);
        resolve(data);
      })["catch"](function (error) {
        reject(error);
      });
    });
  },
  logout: function logout(_ref3) {
    var commit = _ref3.commit;
    return new Promise(function (resolve) {
      removeToken();
      commit('RESET_STATE');
      resolve();
    });
  }
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(State, token) {
    State.token = token;
  },
  RESET_STATE: function RESET_STATE(State) {
    State.user = null;
  },
  SET_USER: function SET_USER(State, user) {
    State.user = user;
  }
};
export default {
  namespace: true,
  state: state,
  actions: actions,
  mutations: mutations
};